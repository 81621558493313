import styled from "styled-components";
import theme from "../../config/theme";

const Link = styled.a`
  & {
    font-size: 1.25rem;
    font-weight: normal;
    color: ${theme.colors.core_blanco};
  }
  &:hover {
    transition: ease-in;
    color: ${theme.colors.alt_blanco};
  }
`;

const Header = styled.header`
  display: flex;
  gap: 7.5rem;
  z-index: 10000;
  padding: 1.5rem 7.5rem;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: ${theme.colors.core_aipreto};
  border-bottom: 0.1rem solid ${theme.colors.alt_aipreto};
  width: 100%;

  @media (max-width: 968px) {
    padding: 2rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
  }
  @media (max-width: 400px) {
    gap: 0.3rem;
    align-items: center;
  }
`;
const Image = styled.img``;

const PageLinks = styled.ul`
width: 100%;
  justify-content: flex-end;
  display: flex;
  gap: 3.5rem;
`;

const Navbar = styled.nav`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ListItem = styled.li`
  list-style-type: none;
`;
export { Link, Header, PageLinks, Navbar, ListItem, Image };
