import styled, { css } from "styled-components";
import theme from "../../config/theme";

const Box = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: ${theme.colors.core_aipreto};
  color: ${theme.colors.core_blanco};
  border-top: 3px solid ${theme.colors.alt_aipreto};
  gap: 2rem;
`;

const BoxInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Link = styled.a`
  color: ${theme.colors.core_blanco};
`;

const BoxSocialMedias = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
`;

const LogoImg = styled.img`
  width: 5rem;
`;

export { Box, BoxInfo, Link, BoxSocialMedias, LogoImg };
