const theme = {
  colors: {
    core_blanco: "#F2F2F2",
    core_aipreto: "#0A0A0A",
    alt_blanco: "#CFCFCF",
    alt_aipreto: "#1E1E1E",
    secondary_vermeio_original: "#FE3849",
    secondary_azur_claro: "#5E61EF",
    secondary_azur_maisclaro: "#d7d7fb",
    secondary_verdin_original: "#00E369",
    secondary_verdin_escuro: "#00B855",
  },
};

export default theme;
