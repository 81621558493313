import styled from "styled-components";

const PageWrapper = styled.body`
  overflow: "hidden";
  scroll-behavior: "smooth";
  gap: "1rem";
  display: "flex";
  flex-direction: "column";
`;

const SectionHero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  gap: 4rem;
`;

const SectionEsg = styled.section`
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

const SectionStep = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
`;
const SectionOurTeam = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 3rem;
`;

const Title = styled.h1`
  margin-top: 10rem;
  font-size: 5.625rem;
  font-weight: bold;
  @media (max-width: 768px) {
    margin-top: 11rem;
    text-align: center;
    font-size: 3rem;
  }
  @media (max-width: 400px) {
    margin-top: 15rem;
    text-align: center;
    font-size: 3rem;
  }
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
`;

const Text = styled.p`
  font-size: 1.5rem;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const SubTitle = styled.h2`
  font-size: 3.75rem;
  font-weight: bold;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 2rem;
  }
`;

const BoxCardIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

const StepBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 13rem;
  flex-wrap: wrap;

  @media (max-width: 1300px) {
    gap: 1rem;
  }
`;

const BoxContentText = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  max-width: 70rem;
`;

const StepImage = styled.img`
  width: 20rem;
  height: 20rem;
  @media (max-width: 300px) {
    width: 15rem;
    height: 15rem;
  }
`;

const CardLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
`;

export {
  SectionHero,
  Title,
  BoxContentText,
  StepBox,
  Text,
  SectionStep,
  TextBox,
  SectionEsg,
  SubTitle,
  StepImage,
  BoxCardIndicator,
  SectionOurTeam,
  CardLine,
  PageWrapper,
};
