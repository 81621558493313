import React from "react";
import * as S from "./style";
import Logo from "../../../assets/logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <S.Box>
      <S.LogoImg src={Logo} />
      <hr />
      <div>
        <h4>contato</h4>
        <p>contato@appgaia.com.br</p>
        <br />
        <h4>contate-nos também pelas redes sociais! ;)</h4>
      </div>

      <hr />

      <S.BoxInfo>
        <Link
          to={
            "https://docs.google.com/document/d/1nFkEVEMIdSZpT6uDPqIEqNmnys4iB1AJCZXZNpope-s/edit?usp=sharing"
          }
        >
          <p>termos de uso</p>
        </Link>
        <S.BoxSocialMedias>
          <img
            src={
              "https://gaiapictures.blob.core.windows.net/store/google-play.svg"
            }
          />
          <img
            src={
              "https://gaiapictures.blob.core.windows.net/store/instagram.svg"
            }
          />
          <img
            src={"https://gaiapictures.blob.core.windows.net/store/tiktok.svg"}
          />
        </S.BoxSocialMedias>
      </S.BoxInfo>
    </S.Box>
  );
};

export default Footer;
