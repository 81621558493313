import React from "react";
import * as S from "./style";

interface IStepIndicatorProps {
  step: string;
  text: string;
  subTitle: string;
}

const StepIndicator = ({ step, subTitle, text }: IStepIndicatorProps) => {
  return (
    <S.Box>
      <S.BoxNumber>
        <S.SubTitle>{step}</S.SubTitle>
      </S.BoxNumber>
      <S.StepTextBox>
        <S.SubTitle>{subTitle}</S.SubTitle>
        <S.Text>{text}</S.Text>
      </S.StepTextBox>
    </S.Box>
  );
};

export default StepIndicator;
