import { createGlobalStyle } from "styled-components";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`

  body{
    background-color: ${theme.colors.core_aipreto};
  }
  * {
    margin: 0;
    padding: 0;
    color: ${theme.colors.core_blanco};
    font-family: 'Space Grotesk', sans-serif;
    box-sizing: border-box;
  }
  li {
    list-style: none;
  }
  a{
    text-decoration: none;
  }
`;

export default GlobalStyle;
