import styled from "styled-components";
import theme from "../../config/theme";

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;

  @media (max-width: 578px) {
    max-width: 80%;
  }
`;

const StepTextBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-size: 1.2rem;
  max-width: 30rem;
`;

const SubTitle = styled.h4`
  font-size: 2rem;
  font-weight: bold;
`;

const BoxNumber = styled.div`
  background-color: ${theme.colors.alt_aipreto};
  display: flex;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
`;

export { Box, StepTextBox, Text, SubTitle, BoxNumber };
