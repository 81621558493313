import React, { useEffect, useState } from "react";
import * as S from "./styles";
import Header from "../../ui/components/Header";
import { CardIndicator } from "../../ui/components/CardIndicator";
import StepIndicator from "../../ui/components/StepIndicator";
import NotificationImg from "../../assets/notification.svg";
import IndicatorStepImg from "../../assets/indicator-step.svg";
import PostImg from "../../assets/post.svg";
import RafaImage from "../../assets/rafa.jpeg";
import AnaImage from "../../assets/ana.jpeg";
import NanaImage from "../../assets/nana.jpg";
import AlecImage from "../../assets/alec.jpeg";
import MillaImage from "../../assets/milla.png";
import LanzoniImage from "../../assets/lanzoni.jpeg";
import FidasImage from "../../assets/fidas.jpeg";
import CardPerson from "../../ui/components/CardPerson/index";
import Footer from "../../ui/components/Footer";
const HomePage = () => {
  return (
    <S.PageWrapper>
      <Header />
      <S.SectionHero id="hero">
        <S.Title>
          salvando o mundo, <br /> uma foto de cada vez
        </S.Title>

        <S.TextBox>
          <S.Text>
            acreditamos que a educação é a chave para construir um futuro mais
            sustentável e inclusivo.
          </S.Text>
          <S.Text>
            através de pequenas ações diárias, vamos criar um futuro sustentável
            ;)
          </S.Text>
        </S.TextBox>
      </S.SectionHero>
      <S.SectionEsg id="section-esg">
        <S.SubTitle>engaje nos 3 meios... </S.SubTitle>
        <S.Text>cuide do planeta onde ele mais precisa!</S.Text>
        <S.BoxCardIndicator>
          <CardIndicator indicator={"[E]"} />
          <CardIndicator indicator={"[S]"} />
          <CardIndicator indicator={"[G]"} />
        </S.BoxCardIndicator>
        <S.BoxContentText>
          <S.Text>
            por meio do Gaia, buscamos fornecer conhecimento e incentivar
            práticas relacionadas à sustentabilidade, responsabilidade social e
            governança corporativa. queremos capacitar as novas gerações a
            entender como esses conceitos se aplicam em suas vidas diárias e
            como podem fazer a diferença.
          </S.Text>
        </S.BoxContentText>
      </S.SectionEsg>
      <S.SectionStep id="section-step">
        <S.SubTitle>realize de forma simples</S.SubTitle>
        <S.BoxContentText>
          <S.Text>pequenas ações juntas fazem a diferença! </S.Text>
        </S.BoxContentText>
        <S.StepBox>
          <StepIndicator
            step="1"
            subTitle="receba a notificação"
            text="o gatilho para uma jornada de mudança no mundo"
          />
          <S.StepImage src={NotificationImg} />
        </S.StepBox>
        <S.StepBox>
          <StepIndicator
            step="2"
            subTitle="use sua criatividade"
            text="decida como você será um agente da mudança no mundo."
          />
          <S.StepImage src={IndicatorStepImg} />
        </S.StepBox>
        <S.StepBox>
          <StepIndicator
            step="3"
            subTitle="pratique e engaje"
            text="registre suas próprias ações e se conecte com uma comunidade engajada!"
          />
          <S.StepImage src={PostImg} />
        </S.StepBox>
      </S.SectionStep>
      <S.SectionOurTeam>
        <S.SubTitle>a equipe dos sonhos</S.SubTitle>
        <S.Text>quem faz o gaia acontecer S2</S.Text>
        <S.CardLine>
          <CardPerson
            name="damasceno"
            functionName="dev back-end"
            img={AnaImage}
          />
          <CardPerson
            name="nana"
            functionName="dev front-end + ux"
            img={NanaImage}
          />
          <CardPerson name="alec" functionName="social media" img={AlecImage} />
          <CardPerson
            name="fidas"
            functionName="dev front-end"
            img={FidasImage}
          />
        </S.CardLine>
        <S.CardLine>
          <CardPerson
            name="lanzoni"
            functionName="social media"
            img={LanzoniImage}
          />
          <CardPerson name="milla" functionName="adm + ux" img={MillaImage} />
          <CardPerson
            name="rafa"
            functionName="dev front-end"
            img={RafaImage}
          />
        </S.CardLine>
      </S.SectionOurTeam>
      <Footer />
    </S.PageWrapper>
  );
};

export default HomePage;
