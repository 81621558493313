import styled, { css } from "styled-components";
import theme from "../../config/theme";

interface AlertBoxTagProps {
  top?: string;
  left?: string;
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
`;
const Image = styled.img`
  width: 15rem;
  object-fit: cover;
  height: 13.5rem;
  border: solid;
  border-color: ${theme.colors.core_blanco};
  border-radius: 0.5rem;
`;

const Text = styled.p`
  font-size: 1.1rem;
  color: ${theme.colors.core_aipreto};
`;
const AlertBox = styled.div`
  width: 17rem;
  height: fit-content;
  border-radius: 0.5rem;
  display: flex;
  padding: 1.625rem 1.5rem 1rem 1.5rem;
  background-color: ${theme.colors.core_blanco};
  position: relative;
`;

const AlertBoxTag = styled.div`
  position: absolute;
  top: -1rem;
  background-color: ${theme.colors.secondary_azur_maisclaro};
  width: fit-content;
  height: fit-content;
  border: solid ${theme.colors.alt_aipreto};
  padding: 0.375rem 1.5rem;
  border-radius: 0.5rem;
`;
export { Box, Image, AlertBox, Text, AlertBoxTag };
