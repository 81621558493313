import React from "react";
import * as S from "./style";

interface ICardPersonProps {
  img: string;
  name: string;
  functionName: string;
}

const CardPerson = ({ functionName, img, name }: ICardPersonProps) => {
  return (
    <S.Box>
      <S.Image src={img} />
      <S.AlertBox>
        <S.AlertBoxTag>
          <S.Text>{name}</S.Text>
        </S.AlertBoxTag>
        <S.Text>{functionName}</S.Text>
      </S.AlertBox>
    </S.Box>
  );
};

export default CardPerson;
