import React from "react";
import * as S from "./style";

const Header = () => {
  return (
    <S.Header>
      <S.Link href="/">
        <S.Image
          src={require("../../../assets/logo.svg").default}
          alt="logo do gaia"
        />
      </S.Link>

      <S.Navbar>
        <S.PageLinks>
          <li>
            <S.Link
              href="https://play.google.com/store/apps/details?id=com.gaiaappv1mvp&pcampaignid=web_share"
              target="_blank"
            >
              <S.Image
                src={
                  "https://gaiapictures.blob.core.windows.net/store/google-play.svg"
                }
                alt="play store logo"
              />
            </S.Link>
          </li>
          <li>
            <S.Link href="https://instagram.com/appgaia" target="_blank">
              <S.Image
                src={
                  "https://gaiapictures.blob.core.windows.net/store/instagram.svg"
                }
                alt="tiktok logo"
              />
            </S.Link>
          </li>
          <li>
            <S.Link href="https://tiktok.com/@appgaia" target="_blank">
              <S.Image
                src={
                  "https://gaiapictures.blob.core.windows.net/store/tiktok.svg"
                }
                alt="instagram logo"
              />
            </S.Link>
          </li>
        </S.PageLinks>
      </S.Navbar>
    </S.Header>
  );
};

export default Header;
