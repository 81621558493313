import styled, { css } from "styled-components";
import theme from "../../config/theme";

const Card = styled.div(
  ({ color }) => css`
    display: flex;
    flex-direction: column;
    border: 0.3rem solid ${color};
    border-radius: 0.5rem;
    width: 22rem;
    min-height: 17rem;
    padding: 2rem;
    gap: 1rem;
    @media (max-width: 578px) {
      width: 18rem;
    }
  `
);

const TitleIndicator = styled.p(
  () => css`
    font-size: 1.5rem;
    color: ${theme.colors.core_blanco};
    font-weight: 700;
  `
);

const TextIndicator = styled.p(
  () => css`
    font-size: 1.5rem;
    color: ${theme.colors.core_blanco};
    @media (max-width: 400px) {
      font-size: 1.25rem;
    }
  `
);

const CardImage = styled.img(
  () => css`
    width: 2.5rem;
  `
);
export { Card, TitleIndicator, CardImage, TextIndicator };
