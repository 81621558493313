import React from "react";
import { useEffect, useState } from "react";
import * as S from "./styles";
import theme from "../../config/theme";
import LeafImg from "../../../assets/leaf.svg";
import HeartHandImg from "../../../assets/heart-hand.svg";
import GovernanceImg from "../../../assets/governance.svg";

interface ICardIndicatorProps {
  indicator: "[E]" | "[S]" | "[G]";
}

const CardIndicator = ({ indicator }: ICardIndicatorProps) => {
  const [color, setColor] = useState<string>();
  const [img, setImg] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [text, setText] = useState<string>();

  useEffect(() => {
    switch (indicator) {
      case "[E]":
        setColor(theme.colors.secondary_verdin_original);
        setImg(LeafImg);
        setTitle("ambiental");
        setText("Práticas sustentáveis voltadas ao meio ambiente.");
        break;

      case "[S]":
        setColor(theme.colors.secondary_vermeio_original);
        setImg(HeartHandImg);
        setTitle("social");
        setText("Práticas voltadas à responsabilidade social.");
        break;

      case "[G]":
        setColor(theme.colors.secondary_azur_claro);
        setImg(GovernanceImg);
        setTitle("governança");
        setText("Práticas voltadas à administração consciente.");

        break;
    }
  }, [indicator]);

  return (
    <S.Card color={color}>
      <S.CardImage src={img} alt={indicator} />
      <S.TitleIndicator>{title}</S.TitleIndicator>
      <S.TextIndicator>{text}</S.TextIndicator>
    </S.Card>
  );
};

export { CardIndicator };
